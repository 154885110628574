import React from "react";
import { Link } from "react-router-dom";
const FooterBar = () => {
  return (
    <div className='main-footer'>
      <div className='row'>
        <div className='col-xs-4 col-sm-4 white'>
          <p className='footer-text'>
            &copy; {new Date().getFullYear()} Naroci-se.com
          </p>
        </div>
        <div className='col-xs-4 col-sm-4 white'>
          <p className='footer-text'>
            <Link to='/pomoc'>Kontakt in pomoč</Link>
          </p>
        </div>
        <div className='col-xs-4 col-sm-4 white'>
          <p className='footer-text'>
            <Link to='/politika-zasebnosti'>Politika zasebnosti</Link>
          </p>
        </div>
      </div>
      <p className='footer-text'>Spletna stran je v razvoju</p>
    </div>
  );
};

export default FooterBar;
