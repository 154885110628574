import React, { useRef, useState } from "react";
import { Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { RegisterUser } from "../misc/Auth";
import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

const RegisterForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const nameRef = useRef();
  const surnameRef = useRef();
  const emailRef = useRef();
  const phoneNumberRef = useRef();
  const passwordRef = useRef();
  const repeatPasswordRef = useRef();

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const FailureMessage = ({ status }) => {
    if (status === "nomatch") {
      return "Gesli se ne ujemata!";
    } else if (status === "server-error") {
      return "Napaka na strežniku!";
    } else if (status === "incomplete-data") {
      return "Nepopolni podatki!";
    } else if (status === false) {
      return "Uporabnik s tem elektronskim naslovom že obstaja.";
    }
  };

  const successAlert = () =>
    new toast.success(<p>Registracija uspešna, prijavite se</p>);

  const failureAlert = (status) =>
    new toast.error(<FailureMessage status={status} />);

  const SubmitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const name = nameRef.current.value;
    const surname = surnameRef.current.value;
    const email = emailRef.current.value;
    const phoneNumber = phoneNumberRef.current.value;
    const password = passwordRef.current.value;
    const repeatPassword = repeatPasswordRef.current.value;

    if (
      !name ||
      !surname ||
      !email ||
      !phoneNumber ||
      !password ||
      !repeatPassword
    ) {
      failureAlert("incomplete-data");
      setLoading(false);
      return;
    } else if (password !== repeatPassword) {
      failureAlert("nomatch");
      setLoading(false);
    } else {
      const status = await RegisterUser(
        name,
        surname,
        email,
        phoneNumber,
        password
      );
      switch (status) {
        case 200: {
          successAlert();
          setLoading(false);
          delay(2000).then(() => navigate("/prijava"));
          break;
        }
        case 409: {
          failureAlert(false);
          setLoading(false);
          break;
        }
        case 500: {
          failureAlert("server-error");
          setLoading(false);
          break;
        }
        default: {
          console.log(status);
        }
      }
    }
  };
  return (
    <Container className='login-container'>
      <ToastContainer position='top-center' />
      {loading ? <Oval color='black' secondaryColor='gray'></Oval> : ""}
      <Form className='register-grid'>
        <div className='grid-column'>
          <Form.Group className='mb-3' controlId='formBasicName'>
            <Form.Label>Ime</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={1}
              type='email'
              placeholder='Ime'
              size='lg'
              ref={nameRef}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicSurname'>
            <Form.Label>Priimek</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={2}
              type='email'
              placeholder='Priimek'
              size='lg'
              ref={surnameRef}
            />
          </Form.Group>
        </div>

        <div className='grid-column'>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label>Elektronska pošta</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={3}
              type='email'
              placeholder='Elektronska posta'
              size='lg'
              ref={emailRef}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPhone'>
            <Form.Label>Telefonska številka</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={4}
              type='email'
              placeholder='Telefonska Stevilka'
              size='lg'
              ref={phoneNumberRef}
            />
          </Form.Group>
        </div>

        <div className='grid-column'>
          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label>Geslo</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={5}
              type='password'
              placeholder='Geslo'
              size='lg'
              ref={passwordRef}></Form.Control>
          </Form.Group>

          <Form.Group className='mb-3' controlId='formRepeatPassword'>
            <Form.Label>Ponovi Geslo</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={6}
              type='password'
              placeholder='Ponovi geslo'
              size='lg'
              ref={repeatPasswordRef}
            />
            <p className='register-text' onClick={() => navigate("/prijava")}>
              Že imaš račun? Prijavi se!
            </p>
          </Form.Group>
        </div>
      </Form>
      <div className='buttons'>
        <button
          style={{ "margin-top": "2vh" }}
          tabIndex={7}
          variant='success'
          type='submit'
          onClick={(e) => SubmitHandler(e)}>
          Registriraj
        </button>
      </div>
    </Container>
  );
};

export default RegisterForm;
