import Offer from "../components/Offer";
import { useState } from "react";
import Pagination from "../components/Pagination";
import Filters from "../components/Filters";

const Offers = () => {
  const [value, onChange] = useState([
    new Date(),
    new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  ]);
  const [nrOfPages, setNrOfPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const local_date_one = value[0].toLocaleDateString("en-US").split("/");
  const local_date_two = value[1].toLocaleDateString("en-US").split("/");
  let date = `${local_date_one[2]}-${local_date_one[0]}-${local_date_one[1]}`;
  let date2 = `${local_date_two[2]}-${local_date_two[0]}-${local_date_two[1]}`;

  const [paid, setPaid] = useState(0);

  const [selectedFilter, setSelectedFilter] = useState(false);

  return (
    <div className='offers-container'>
      <Filters
        value={value}
        onChange={onChange}
        setSelectedFilter={setSelectedFilter}
        paid={paid}
        setPaid={setPaid}
        setCurrentPage={setCurrentPage}></Filters>
      <Offer
        date={date}
        date2={date2}
        setNrOfPages={setNrOfPages}
        currentPage={currentPage}
        sortRegion={selectedFilter ? selectedFilter.value : selectedFilter}
        paid={paid}></Offer>
      <div className='pagination-container'>
        <Pagination
          nrOfPages={nrOfPages}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}></Pagination>
      </div>
    </div>
  );
};

export default Offers;
