import React, { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Select from "react-select";
import axios from "axios";
import { Oval } from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";

const Filters = ({
  value,
  onChange,
  setSelectedFilter,
  setPaid,
  MFQ,
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(false);

  const paidOptions = [
    { value: 0, label: "Vsi" },
    { value: 1, label: "Samoplacnisko" },
    { value: 2, label: "Preko dodatnega zavarovanja" },
    { value: 3, label: "Na napotnico" },
  ];

  const { id } = useParams();
  const [filters, setFilters] = useState([]);
  const [filterLoading, setFilterLoading] = useState(false);

  useEffect(() => {
    if (MFQ) {
      setFilterLoading(true);
      axios
        .get(
          process.env.REACT_APP_HOST_API + "/public/storitvevkategoriji/" + id,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setFilters(res.data);
          setFilterLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  }, [id, MFQ]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(process.env.REACT_APP_HOST_API + "/public/regije", {
        withCredentials: true,
      })
      .then((res) => {
        res.data.unshift({ value: false, label: "Vse" });
        setRegions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className='filters-container'>
        {MFQ ? (
          <div className='regions-box'>
            <div className='instruction'>Izberi storitev: </div>
            <Select
              placeholder={
                filterLoading ? (
                  <Oval height={25} width={25}></Oval>
                ) : (
                  "Izberi storitev"
                )
              }
              options={filters}
              onChange={(e) => navigate(`/ponudba/${e.value}`)}></Select>
          </div>
        ) : null}
        <div className='daterange-box'>
          <div className='instruction'>Izberi datum: </div>
          <DateRangePicker
            onChange={(e) => {
              onChange(e);
              setCurrentPage(1);
            }}
            value={value}
            format={"dd.MM.yyyy"}
            locale={"sl-SL"}
            clearIcon={null}></DateRangePicker>
        </div>
        <div className='regions-box'>
          <div className='instruction'>Izberi regijo: </div>
          <Select
            placeholder={
              loading ? <Oval height={25} width={25}></Oval> : "Izberi regijo"
            }
            options={regions}
            onChange={(e) => {
              setSelectedFilter(e.value);
              setCurrentPage(1);
            }}></Select>
        </div>
        <div className='paid-box'>
          <div className='instruction'>Izberi nacin narocanja:</div>
          <Select
            placeholder='Vsi'
            options={paidOptions}
            onChange={(e) => {
              setPaid(e.value);
              setCurrentPage(1);
            }}></Select>
        </div>
      </div>
    </>
  );
};

export default Filters;
