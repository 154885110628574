import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import axios from "axios";
import { useParams } from "react-router";
import { Fragment, useEffect, useState } from "react";
import { AuthConsumer } from "react-check-auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { Oval } from "react-loader-spinner";
import { Badge } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

const MFQOffer = ({
  date,
  date2,
  setNrOfPages,
  currentPage,
  sortRegion,
  paid,
}) => {
  const [userInfo, setUserInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const AlertMessage = () => {
    return (
      <Fragment>
        <p>
          Poslali smo vam elektronsko sporočilo na{" "}
          <span style={{ fontWeight: "bold", color: "green" }}>
            {userInfo.elektronska_posta}
          </span>
          <p>Tam lahko nadaljujete postopek rezervacije.</p>
        </p>
      </Fragment>
    );
  };

  const notify = () => new toast.success(<AlertMessage />);

  async function reserve() {
    setLoading(true);
    await axios
      .get(
        process.env.REACT_APP_HOST_API +
          `/rezervacija/rezerviraj/${data.sifra}`,
        { withCredentials: true }
      )
      .then(() => {
        setLoading(false);
        notify();
        setShowModal(false);
        setRefreshData(refreshData + 1);
      });
  }

  const [refreshData, setRefreshData] = useState(0);

  console.log(showModal);

  const OfferModal = () => {
    if (data) {
      return (
        <Modal
          className='modal'
          show={showModal}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered>
          <Modal.Header style={{ flexDirection: "column" }}>
            <Modal.Title id='contained-modal-title-vcenter'>
              {data.naziv}
            </Modal.Title>
            <img src={data.img} alt='logotip izvajalca'></img>
          </Modal.Header>
          <Modal.Body>
            <h4>{data.naziv_vzs}</h4>
            <ul>
              {userInfo ? <li>Osebje: {data.osebje}</li> : null}
              <li>Datum: {data.datum}</li>
              {userInfo ? <li>Cena: {data.cena}</li> : null}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            {loading ? <Oval></Oval> : ""}
            {userInfo ? (
              <Button onClick={() => reserve()}>Rezerviraj</Button>
            ) : (
              <Button disabled>Za rezervacijo se prijavi</Button>
            )}
            <Button onClick={() => setShowModal(false)}>Zapri</Button>
          </Modal.Footer>
        </Modal>
      );
    } else return null;
  };

  const { id } = useParams();
  const [offerResults, setOfferResults] = useState(null);

  const [dateSort, setDateSort] = useState(0);
  const [priceSort, setPriceSort] = useState(0);

  function handleDateSort(value) {
    if (dateSort === value) {
      setDateSort(0);
    } else {
      setPriceSort(0);
      setDateSort(value);
    }
  }

  function handlePriceSort(value) {
    if (priceSort === value) {
      setPriceSort(0);
    } else {
      setDateSort(0);
      setPriceSort(value);
    }
  }

  useEffect(() => {
    function fetchData() {
      setOfferResults(null);
      return axios
        .get(
          process.env.REACT_APP_HOST_API +
            `/public/termini-categorized/${id}/${date}/${date2}/${currentPage}/${sortRegion}/${priceSort}/${dateSort}/${paid}`,
          {
            withCredentials: true,
          }
        )
        .then((res) => {
          setNrOfPages(res.data.steviloStrani);
          setOfferResults(res.data.podatki);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    fetchData();
  }, [
    id,
    setNrOfPages,
    date,
    date2,
    currentPage,
    sortRegion,
    priceSort,
    dateSort,
    paid,
    refreshData,
  ]);

  const SingleMFQOffer = ({ offerResults }) => {
    if (offerResults !== null) {
      return (
        <Container>
          <AuthConsumer>
            {({ userInfo }) => {
              if (userInfo) {
                setUserInfo(userInfo);
              }
            }}
          </AuthConsumer>
          {offerResults.length === 0 ? (
            <div className='no-offers'> Za ta datum ni najdenih terminov.</div>
          ) : (
            <div>
              <Container className='offers'>
                <div className='sort-box'>
                  <div className='sort-date'>
                    Datum
                    <FontAwesomeIcon
                      className={
                        dateSort === "1" ? "sort-icons-active" : "sort-icons"
                      }
                      size='lg'
                      icon={faArrowUp}
                      onClick={() => handleDateSort("1")}></FontAwesomeIcon>
                    <FontAwesomeIcon
                      size='lg'
                      className={
                        dateSort === "-1" ? "sort-icons-active" : "sort-icons"
                      }
                      icon={faArrowDown}
                      onClick={() => handleDateSort("-1")}></FontAwesomeIcon>
                  </div>
                  {userInfo ? (
                    <div className='sort-price'>
                      Cena
                      <FontAwesomeIcon
                        className={
                          priceSort === "1" ? "sort-icons-active" : "sort-icons"
                        }
                        size='lg'
                        icon={faArrowUp}
                        onClick={() => handlePriceSort("1")}></FontAwesomeIcon>
                      <FontAwesomeIcon
                        size='lg'
                        className={
                          priceSort === "-1"
                            ? "sort-icons-active"
                            : "sort-icons"
                        }
                        icon={faArrowDown}
                        onClick={() => handlePriceSort("-1")}></FontAwesomeIcon>
                    </div>
                  ) : null}
                </div>
                {offerResults.map((result, idx) => (
                  <Fragment key={idx}>
                    <Row className='offer-row'>
                      <Col className='offer-image'>
                        <img src={result.img} alt='Slika ponudnika' />
                      </Col>
                      <Col>
                        <h5>{result.naziv_vzs}</h5>
                        <h6>{result.naziv}</h6>
                        <h6>{result.osebje}</h6>
                      </Col>
                      <Col>
                        <h6>{result.datum}</h6>
                        {userInfo ? <h6>Cena: {result.cena}</h6> : null}
                        {result.samoplacnisko ? (
                          result.dodatno ? (
                            <>
                              <Badge>Samoplačniško</Badge>
                              <Badge style={{ marginTop: "0.4rem" }}>
                                Preko dodatnega zavarovanja
                              </Badge>
                            </>
                          ) : (
                            <Badge>Samoplačniško</Badge>
                          )
                        ) : result.dodatno ? (
                          <Badge>Preko dodatnega zavarovanja</Badge>
                        ) : (
                          <Badge>Na napotnico</Badge>
                        )}
                      </Col>
                      <Col>
                        <button
                          style={{ background: "#0D6EFD", marginTop: "1rem" }}
                          onClick={() => {
                            setData(result);
                            setShowModal(true);
                          }}>
                          Naroči se
                        </button>
                      </Col>
                    </Row>
                  </Fragment>
                ))}
              </Container>
            </div>
          )}
        </Container>
      );
    } else {
      return (
        <Container className='loading-container'>
          <Oval color='black' secondaryColor='gray' ariaLabel='loading'></Oval>
        </Container>
      );
    }
  };

  return (
    <Container>
      <ToastContainer position='top-center' />
      <SingleMFQOffer offerResults={offerResults}></SingleMFQOffer>
      <OfferModal data={data} show={showModal}></OfferModal>
    </Container>
  );
};

export default MFQOffer;
