import axios from "axios";

async function LoginUser(username, password) {
  const status = await axios
    .post(
      process.env.REACT_APP_HOST_API + "/auth/login",
      {
        username: username,
        password: password,
      },
      { withCredentials: true }
    )
    .then((data) => {
      return data.status;
    })
    .catch((err) => {
      return err.response.status;
    });
  return status;
}

async function RegisterUser(name, surname, email, phoneNumber, password) {
  const status = await axios
    .post(
      process.env.REACT_APP_HOST_API + "/auth/register",
      {
        ime: name,
        priimek: surname,
        elektronska_posta: email,
        telefonska_stevilka: phoneNumber,
        geslo: password,
      },
      { withCredentials: true }
    )
    .then((res) => {
      return res.status;
    })
    .catch((err) => {
      return err.response.status;
    });
  return status;
}

export { LoginUser, RegisterUser };
