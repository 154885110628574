import { Nav, NavDropdown } from "react-bootstrap";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthConsumer } from "react-check-auth";
import useWindowDimensions from "../hooks/WindowResize";
import axios from "axios";
import { useState } from "react";
import MobileMenu from "./MobileMenu";

const NavBar = () => {
  const location = useLocation().pathname;
  const navigate = useNavigate();

  const { width } = useWindowDimensions();

  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const MobileNav = () => {
    return (
      <div className='mobileicons'>
        <FontAwesomeIcon
          className='navigation-button'
          icon={faBars}
          size='2x'
          onClick={() => setIsMobileMenu(!isMobileMenu)}></FontAwesomeIcon>
        <AuthConsumer>
          {(userInfo, isLoading, error) => {
            if (userInfo.userInfo) {
              return (
                <div className='initalsinnavbar'>
                  {userInfo.userInfo.avatar}
                </div>
              );
            } else return null;
          }}
        </AuthConsumer>
      </div>
    );
  };

  const NormalNav = () => {
    return (
      <div className='navlinks'>
        <Nav variant='pills'>
          <Nav.Item className='link'>
            <button
              className={location === "/" ? "navbutton-active" : "navbutton"}
              onClick={() => navigate("/")}>
              Domov
            </button>
          </Nav.Item>
          <AuthConsumer>
            {(userInfo, isLoading, error) => {
              if (userInfo.userInfo) {
                return (
                  <div className='placeholder-div'>
                    <NavDropdown className='link' title={userInfo.userInfo.ime}>
                      <NavDropdown.Item onClick={() => navigate("/mojracun")}>
                        Moj račun
                      </NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item
                        onClick={async () => {
                          await axios
                            .get(
                              process.env.REACT_APP_HOST_API + "/auth/logout",
                              {
                                withCredentials: true,
                              }
                            )
                            .then(() => {
                              userInfo.refreshAuth();
                              window.location.reload();
                            });
                        }}>
                        Odjava
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Item>{userInfo.userInfo.avatar}</Nav.Item>
                  </div>
                );
              } else {
                return (
                  <Nav.Item className='link'>
                    <button
                      className={
                        location === "/prijava"
                          ? "navbutton-active"
                          : "navbutton"
                      }
                      onClick={() => navigate("/prijava")}>
                      Prijava
                    </button>
                  </Nav.Item>
                );
              }
            }}
          </AuthConsumer>
        </Nav>
      </div>
    );
  };

  return (
    <div>
      <nav>
        <h4 className='logo'>Vse zdravstvene storitve na enem mestu</h4>
        {width < 900 ? <MobileNav></MobileNav> : <NormalNav></NormalNav>}
      </nav>
      {width < 900 && isMobileMenu ? (
        <MobileMenu
          isMobileMenu={isMobileMenu}
          setIsMobileMenu={setIsMobileMenu}></MobileMenu>
      ) : null}
    </div>
  );
};

export default NavBar;
