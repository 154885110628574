import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/app.scss";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { AuthProvider } from "react-check-auth";
import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";

import NavBar from "./components/NavBar";
import FooterBar from "./components/FooterBar";
import Home from "./views/Home";
import Offers from "./views/Offers";
import MFQOffers from "./views/MFQOffers";

import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import ForgotForm from "./components/ForgotForm";
import AccountView from "./views/AccountView";
import GDPR from "./views/GDPR";
import Help from "./views/Help";
import CookieConsent from "react-cookie-consent";

import dotenv from "dotenv";
dotenv.config();
const handleDeclineCookie = () => {
  //window['ga-disable-M3BM6P95MN'] = true;
  //Cookies.remove("_ga", { path: '/', domain: '.naroci-se.com' });
  //Cookies.remove("_ga_M3BM6P95MN", { path: '/', domain: '.naroci-se.com' });
};
const handleAcceptCookie = () => {
  //window['ga-disable-M3BM6P95MN'] = false;
  //window.gtag('config', 'G-M3BM6P95MN');
};

function App() {
  return (
    <Router>
      <AuthProvider authUrl={process.env.REACT_APP_HOST_API + "/auth/userdata"}>
        <div className='App'>
          <section>
            <NavBar></NavBar>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/prijava' element={<LoginForm />}></Route>
              <Route path='/pozabljeno' element={<ForgotForm />}></Route>
              <Route path='/registracija' element={<RegisterForm />}></Route>
              <Route path='/ponudba/:id' element={<Offers />}></Route>
              <Route path='ponudba-NPK/:id/1' element={<MFQOffers />}></Route>
              <Route path='/mojracun' element={<AccountView />}></Route>
              <Route path='/politika-zasebnosti' element={<GDPR />}></Route>
              <Route path='/pomoc' element={<Help />}></Route>
            </Routes>
          </section>
          <FooterBar></FooterBar>
        </div>
      </AuthProvider>
      <CookieConsent
        location='bottom'
        buttonText='Zapri'
        cookieName='SprejetjePiskotkov'
        style={{ background: "#2B373B" }}
        buttonStyle={{
          color: "#000000",
          fontSize: "14px",
          background: "#8fcc8f",
          borderRadius: "5px",
        }}>
        Ta spletna stran uporablja le piškotke, ki so nujno potrebni za
        delovanje spletne strani. Več o piškotkih in politiki zasebnosti si
        lahko preberete{" "}
        <Link style={{ textDecoration: "underline" }} to='/politika-zasebnosti'>
          tukaj
        </Link>
        .
      </CookieConsent>
    </Router>
  );
}

export default App;
