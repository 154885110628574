import { Container } from "react-bootstrap";
import React, { Fragment } from "react";
import { AuthConsumer } from "react-check-auth";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({ isMobileMenu, setIsMobileMenu }) => {
  const HandleLogout = async () => {
    await axios.get(process.env.REACT_APP_HOST_API + "/auth/logout", {
      withCredentials: true,
    });
  };

  const navigate = useNavigate();

  return (
    <Container>
      <ul className='mobilemenu'>
        <p
          className='mobilelink'
          onClick={() => {
            setIsMobileMenu(!isMobileMenu);
            navigate("/");
          }}>
          Domov
        </p>
        <AuthConsumer>
          {(userInfo, isLoading, error) => {
            if (!userInfo.userInfo) {
              return (
                <p
                  href='/prijava'
                  className='mobilelink'
                  onClick={() => {
                    setIsMobileMenu(!isMobileMenu);
                    navigate("/prijava");
                  }}>
                  Prijava
                </p>
              );
            } else
              return (
                <Fragment>
                  <p
                    className='mobilelink'
                    onClick={() => {
                      setIsMobileMenu(!isMobileMenu);
                      navigate("/mojracun");
                    }}>
                    Moj račun
                  </p>
                  <p
                    className='mobilelink'
                    onClick={() => {
                      HandleLogout();
                      setIsMobileMenu(!isMobileMenu);
                      userInfo.refreshAuth();
                      navigate("/");
                    }}>
                    Odjava
                  </p>
                </Fragment>
              );
          }}
        </AuthConsumer>
      </ul>
    </Container>
  );
};

export default MobileMenu;
