import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Card } from "react-bootstrap";
import { MutatingDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Grid = () => {
  const GridCards = () => {
    const [results, setResults] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
      const searchString = process.env.REACT_APP_HOST_API + "/public/npk";
      axios
        .get(searchString, {
          withCredentials: true,
        })
        .then((res) => {
          setResults(res.data);
        });
    }, []);

    const MostWantedCategories = ({ results }) => {
      if (results !== null) {
        return (
          <Fragment>
            <Container className='grid'>
              <div className='advertisement-slot' id='reklama1'>
                <div className='ads'>
                <iframe
                    id='a7e76c08'
                    name='a7e76c08'
                    src='https://oglasi.proemium.com/www/delivery/afr.php?zoneid=1&amp;cb=INSERT_RANDOM_NUMBER_HERE'
                    scrolling='no'
                    width='236'
                    height='576'
                    allow='autoplay'>
                    <a
                      href='https://oglasi.proemium.com/www/delivery/ck.php?n=a6162804&amp;cb=INSERT_RANDOM_NUMBER_HERE'
                      target='_blank'>
                      <img
                        src='https://oglasi.proemium.com/www/delivery/avw.php?zoneid=1&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a6162804'
                        border='0'
                        alt=''
                      />
                    </a>
                  </iframe>
                </div>
              </div>
              <Row md={5}>
                {results.slice(0, 6).map((result, idx) => (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/ponudba-NPK/${result.uuid}/1`)}
                    key={idx}
                    className='card-slot'>
                    <Card className='cards'>
                      <img
                        className='gridicons'
                        src={result.svgelement}
                        width='100'
                        height='100'
                        alt='slika storitve'
                      />
                      <Card.Body>
                        <Card.Title>{result.naziv}</Card.Title>
                        <Card.Subtitle>{result.kratica}</Card.Subtitle>
                        <Card.Text></Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </Row>
              <div className='advertisement-slot'>
                <div className='ads'>
                  <iframe
                    id='a7e76c08'
                    name='a7e76c08'
                    src='https://oglasi.proemium.com/www/delivery/afr.php?zoneid=1&amp;cb=INSERT_RANDOM_NUMBER_HERE'
                    scrolling='no'
                    width='236'
                    height='576'
                    allow='autoplay'>
                    <a
                      href='https://oglasi.proemium.com/www/delivery/ck.php?n=a6162804&amp;cb=INSERT_RANDOM_NUMBER_HERE'
                      target='_blank'>
                      <img
                        src='https://oglasi.proemium.com/www/delivery/avw.php?zoneid=1&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a6162804'
                        border='0'
                        alt=''
                      />
                    </a>
                  </iframe>
                </div>
              </div>
            </Container>
          </Fragment>
        );
      } else
        return (
          <Container className='loading-container-home'>
            <MutatingDots color='black' ariaLabel='loading'></MutatingDots>
          </Container>
        );
    };

    return <MostWantedCategories results={results}></MostWantedCategories>;
  };

  return <GridCards></GridCards>;
};

export default Grid;
