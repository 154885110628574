import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAnglesLeft,
  faAngleRight,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";

function Pagination({ nrOfPages, currentPage, setCurrentPage }) {
  function handleChangeRight() {
    if (currentPage === nrOfPages) setCurrentPage(1);
    else setCurrentPage(currentPage + 1);
  }

  function handleChangeLeft() {
    if (currentPage === 1) setCurrentPage(nrOfPages);
    else setCurrentPage(currentPage - 1);
  }

  const pageArray = Array.from({ length: nrOfPages }, (_, i) => i + 1);
  const siblingCount = 2;
  let [leftSiblingIndex, setLeftSiblingIndex] = useState(null);
  let [rightSiblingIndex, setRightSiblingIndex] = useState(null);

  useEffect(() => {
    setLeftSiblingIndex(Math.max(currentPage - siblingCount - 1, 0));
    setRightSiblingIndex(Math.min(currentPage + siblingCount, 20));
  }, [currentPage]);

  return (
    <div className='pagination'>
      <FontAwesomeIcon
        icon={faAnglesLeft}
        className='icons'
        onClick={() => setCurrentPage(1)}></FontAwesomeIcon>
      <FontAwesomeIcon
        className='icons'
        icon={faAngleLeft}
        onClick={() => handleChangeLeft()}></FontAwesomeIcon>
      {pageArray.slice(leftSiblingIndex, rightSiblingIndex).map((page, ix) => (
        <li
          key={ix}
          className={
            currentPage === page ? "pagination-item active" : "pagination-item"
          }
          onClick={() => setCurrentPage(page)}>
          {page}
        </li>
      ))}
      <FontAwesomeIcon
        className='icons'
        icon={faAngleRight}
        onClick={() => handleChangeRight()}></FontAwesomeIcon>
      <FontAwesomeIcon
        icon={faAnglesRight}
        className='icons'
        onClick={() => setCurrentPage(nrOfPages)}></FontAwesomeIcon>
    </div>
  );
}

export default Pagination;
