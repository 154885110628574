import { Cookies } from "react-cookie-consent";
const GDPR = () => {
  const removeCookies = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
    Cookies.remove("SprejetjePiskotkov");
    Cookies.remove("sessionid");
    window.location.reload();
  };
  return (
    <div style={{ height: "80vh" }}>
      <button
        style={{
          margin: "1rem",
          background: "black",
          color: "white",
          borderRadius: "5px",
          outline: "none",
          padding: "0.5rem",
          border: "black",
        }}
        onClick={removeCookies}>
        Počisti vse piškotke
      </button>
      <object
        data='https://static.naroci-se.com/politika-zasebnosti.pdf'
        type='application/pdf'
        width='100%'
        height='100%'>
        <p>
          Politika zasebnosti je na voljo{" "}
          <a href='https://static.naroci-se.com/politika-zasebnosti.pdf'>
            TUKAJ
          </a>
          .
        </p>
      </object>
    </div>
  );
};

export default GDPR;
