import axios from "axios";
import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const SearchBar = () => {
  const [results, setResults] = useState([]);
  const searchStringRef = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const search = async () => {
    let searchString = searchStringRef.current.value.trim();
    setResults([]);
    setLoading(true);
    if (searchString === "") {
      setLoading(false);
    }
    if (searchString !== "") {
      await axios
        .post(process.env.REACT_APP_HOST_API + "/public/search", {
          search: searchString,
        })
        .then((res) => {
          setResults(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Container className='search-bar'>
      <div className='napis'>
        <h5>Naroči se na:</h5>
      </div>
      <div className='bar'>
        <div className='search-input'>
          <input
            size='lg'
            placeholder='Poišči storitev!'
            ref={searchStringRef}
            onChange={() => search()}
          />
          <div className='result-box'>
            {loading ? (
              <li>
                <Oval
                  wrapperClass='search-spinner'
                  secondaryColor='gray'
                  color='black'
                  height='30'
                  width='30'></Oval>
              </li>
            ) : null}
            {results.map((result, ix) => (
              <li
                key={ix}
                onClick={() => navigate(`/ponudba/${result.sifra_vzs}`)}>
                {result.naziv_vzs}
              </li>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SearchBar;
