import React, { useRef } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ForgotForm = () => {
  const navigate = useNavigate();
  const emailRef = useRef(false);
  return (
    <Container className='login-container'>
      <div className='circle2'></div>
      <Form className='login-form'>
        <Form.Group className='mb-3' controlId='formBasicEmail'>
          <Form.Label className='login-label'>Elektronska pošta</Form.Label>
          <Form.Control
            type='email'
            placeholder='Elektronska posta'
            size='lg'
            ref={emailRef}
          />
        </Form.Group>
        <Button className='forgot-button' variant='success' type='submit'>
          Ponastavi geslo
        </Button>
        <p className='register-text' onClick={() => navigate("/registracija")}>
          Še nimaš računa? Registriraj se!
        </p>
      </Form>
    </Container>
  );
};

export default ForgotForm;
