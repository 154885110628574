import React, { Fragment, useRef, useState } from "react";
import { Form, Button, Container } from "react-bootstrap";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { LoginUser } from "../misc/Auth";
import { ToastContainer, toast } from "react-toastify";

const LoginForm = () => {
  const emailRef = useRef();
  const passwordRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
  const successAlert = () =>
    new toast.success(<p>Prijava je uspešna. Preusmerjam.</p>);
  const failureAlert = () =>
    new toast.error(<p>Napačno uporabniško ime in/ali geslo.</p>);

  const SubmitHandler = async (e) => {
    setLoading(true);
    e.preventDefault();
    const username = emailRef.current.value;
    const password = passwordRef.current.value;
    const status = await LoginUser(username, password);
    switch (status) {
      case 401: {
        failureAlert();
        setLoading(false);
        break;
      }
      case 200: {
        successAlert();
        setLoading(false);
        delay(1500).then(() => {
          navigate("/");
          window.location.reload(true);
        });
        break;
      }
      default: {
        console.log(status);
      }
    }
  };

  return (
    <Fragment>
      <ToastContainer position='top-center' autoClose={3000}></ToastContainer>
      <Container className='login-container' onSubmit={SubmitHandler}>
        <Form className='login-form'>
          {loading ? <Oval color='black' secondaryColor='gray'></Oval> : ""}
          <h2>Prijava</h2>
          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label className='login-label'>Elektronska pošta</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={1}
              type='email'
              placeholder='Elektronska posta'
              size='lg'
              ref={emailRef}
            />
          </Form.Group>

          <Form.Group className='mb-3' controlId='formBasicPassword'>
            <Form.Label className='login-label'>Geslo</Form.Label>
            <Form.Control
              className='login-input'
              tabIndex={2}
              type='password'
              placeholder='Geslo'
              size='lg'
              ref={passwordRef}
            />
          </Form.Group>
          {/* <p className='forgot-text' onClick={() => navigate("/pozabljeno")}>
            Pozabljeno geslo?
          </p> */}
          <Button type='submit'>Prijavi se</Button>
          <p
            className='register-text'
            onClick={() => navigate("/registracija")}>
            Še nimaš računa? Registriraj se!
          </p>
        </Form>
      </Container>
    </Fragment>
  );
};

export default LoginForm;
