import React from "react";
import Grid from "../components/GridCards";
import SearchBar from "../components/SearchBar";

const Home = () => {
  return (
    <div>
      <SearchBar></SearchBar>
      {/* <div className='adcontainer'>
        <div className='banner'>OGLAS</div>
      </div> */}
      <Grid></Grid>
    </div>
  );
};

export default Home;
