import { AuthConsumer } from "react-check-auth";
import { useNavigate } from "react-router-dom";
import { Oval } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";

const Account = () => {
  const navigate = useNavigate();

  function redirect() {
    navigate("/prijava");
  }

  return (
    <AuthConsumer>
      {(userInfo) => {
        if (userInfo.isLoading) {
          <Oval height={40} width={40}></Oval>;
        } else if (userInfo.userInfo) {
          let userData = userInfo.userInfo;
          console.log(userData);
          {
            return (
              <div className='account-container'>
                <div className='account-box'>
                  <div className='user-short'>
                    <FontAwesomeIcon
                      style={{ marginLeft: "2rem" }}
                      icon={faAddressCard}
                      size='2x'></FontAwesomeIcon>
                    <h3>
                      {userData.ime} {userData.priimek}
                    </h3>
                  </div>
                  <div className='user-long'>
                    <form className='form' action=''>
                      <div className='form-left'>
                        <p>Ime</p>
                        <input
                          type='text'
                          disabled={true}
                          value={userData.ime}
                        />
                        <p>Priimek</p>
                        <input
                          type='text'
                          disabled={true}
                          value={userData.priimek}
                        />
                        <p>Telefonska številka</p>
                        <input
                          type='text'
                          disabled={true}
                          value={userData.telefonska_stevilka}
                        />
                      </div>
                      <div className='form-right'>
                        <p>Elektronska pošta</p>
                        <input
                          type='text'
                          disabled={true}
                          value={userData.elektronska_posta}
                        />
                        <p>Naslov</p>
                        <input
                          type='text'
                          disabled={true}
                          value={
                            userData.naslov
                              ? userData.naslov
                              : "Manjkajoč podatek"
                          }
                        />
                        <p>Pošta</p>
                        <input
                          type='text'
                          disabled={true}
                          value={
                            userData.posta
                              ? userData.posta
                              : "Manjkajoč podatek"
                          }
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            );
          }
        } else {
          redirect();
        }
      }}
    </AuthConsumer>
  );
};

export default Account;
